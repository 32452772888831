<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.994 8.18a4.933 4.933 0 11-6.975 0M11.507 6.416v4.594" stroke="#000" stroke-width=".9" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.507 22.564c6.107 0 11.057-4.95 11.057-11.057S17.614.45 11.507.45.45 5.4.45 11.507 5.4 22.564 11.507 22.564z" stroke="#000" stroke-width=".9" stroke-miterlimit="10" stroke-linecap="round"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconDisconnect'
  };
</script>
