<template>
  <div id="container">
    <div id="body">
      <div :class="dashboardClass" class="dashboard page-container">
        <!-- Faire en sorte que les nav soient fixes -->
        <md-app md-mode="fixed">
          <!-- La navbar -->
          <md-app-toolbar
            :class="{ 'dashboard-jep': isJEP }"
            :md-elevation="1"
            class="dashboard__toolbar"
          >
            <div
              v-show="!(isMobile && isSideNavVisible)"
              class="dashboard__toolbar-item--right"
            >
              <!-- Activer les Notifications -->
              <md-menu
                :md-active="!isSuperUser && isNotificationsVisible"
                class="button-menu"
                md-align-trigger
                md-direction="bottom-end"
              >
                <md-button
                  class="button-menu dashboard-toolbar__button-menu"
                  @click="toggleNotifications"
                >
                  <MeepIconNotifications
                    class="
                      app-icon-stroke-inverted
                      dashboard-icon
                      dashbord__noti-icon
                      dashboard-toolbar__icon
                    "
                  />

                  <md-avatar
                    class="md-avatar-icon md-small md-a ccent"
                    style="top: -6px"
                  >
                    <p>{{ hasUnreadNotif }}</p>
                  </md-avatar>
                </md-button>

                <md-menu-content
                  :class="dashboardClass"
                  class="dashboard__notifications"
                >
                  <md-menu-item>
                    <span
                      class="dashboard__notifications__title md-list-item-text"
                    >
                      Notifications : {{ hasUnreadNotif }}
                    </span>

                    <md-button
                      class="md-icon-button md-list-action"
                      @click="goToListNotifications"
                    >
                      <MeepIconTopBarBoxList class="app-icon toolbar-icon" />
                      <md-tooltip md-direction="top">
                        {{ $t("common.see-all") }}
                      </md-tooltip>
                    </md-button>

                    <!-- Vider la liste -->
                    <md-button
                      class="md-icon-button md-list-action"
                      @click="clearNotifications"
                    >
                      <MeepIconTopBarBoxDelete class="app-icon toolbar-icon" />
                      <md-tooltip md-direction="top">
                        Vider la liste
                      </md-tooltip>
                    </md-button>

                    <md-button
                      class="md-icon-button md-list-action"
                      @click="toggleNotifications"
                    >
                      <MeepIconTopBarBoxClose
                        class="app-icon-stroke toolbar-icon"
                      />
                      <md-tooltip md-direction="top"> Fermer</md-tooltip>
                    </md-button>
                  </md-menu-item>

                  <!-- La liste des notifications-->
                  <md-menu-item
                    v-for="(notif, index) in notificationsNotChat"
                    :key="index"
                    class="notification"
                  >
                    <div v-if="notif.icon === 'cloud'" class="icon-circle">
                      <MeepIconCloud
                        class="app-icon-stroke-inverted toolbar-icon"
                      />
                    </div>

                    <div
                      v-if="notif.icon === 'notifications'"
                      class="icon-circle"
                    >
                      <MeepIconNotifications
                        class="app-icon-stroke-inverted toolbar-icon"
                      />
                    </div>

                    <div v-if="notif.icon === 'person'" class="icon-circle">
                      <MeepIconPerson class="app-icon-inverted toolbar-icon" />
                    </div>

                    <div
                      v-if="notif.icon === 'library_books'"
                      class="icon-circle"
                    >
                      <MeepIconHomepageChat
                        class="app-icon-inverted toolbar-icon"
                      />
                    </div>

                    <div
                      v-if="notif.icon === 'business' || notif.icon === 'work'"
                      class="icon-circle"
                    >
                      <MeepIconSettingCompanies
                        class="app-icon-inverted toolbar-icon"
                      />
                    </div>

                    <div
                      v-if="
                        notif.icon === 'assignment' ||
                          notif.icon === 'assignment_late'
                      "
                      class="icon-circle"
                    >
                      <MeepIconAssignment
                        class="app-icon-inverted toolbar-icon"
                      />
                    </div>

                    <!-- -->
                    <div
                      class="md-list-item-text dashboard__notifications__text"
                      @click="readNotif(notif.id)"
                    >
                      <router-link
                        v-if="notif.url"
                        :title="notif.text"
                        :to="{
                          path: notif.url,
                          query: { id: notif.id, time: Date.now() },
                        }"
                        class="text-secondary"
                      >
                        {{ notif.text }}
                      </router-link>

                      <span v-else :title="notif.text" class="text-secondary">
                        {{ notif.text }}
                      </span>
                    </div>

                    <!-- Bouton pour enlever l'élément de la liste -->
                    <md-button
                      class="md-icon-button md-list-action"
                      @click="removeNotif(notif.id)"
                    >
                      <MeepIconTopBarBoxDelete class="app-icon toolbar-icon" />

                      <md-tooltip md-direction="top"
                        >Retirer de la liste
                      </md-tooltip>
                    </md-button>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>

              <md-menu
                :md-active="!isSuperUser && isNotificationsChatVisible"
                md-align-trigger
                md-direction="bottom-end"
              >
                <md-button
                  class="button-menu dashboard-toolbar__button-menu"
                  @click="toggleNotificationsChat"
                >
                  <MeepIconHomepageChat
                    class="
                      app-icon-inverted
                      dashboard-icon
                      dashboard-toolbar__icon
                    "
                  />

                  <md-avatar
                    class="md-avatar-icon md-small md-accent"
                    style="top: -6px"
                  >
                    <p>{{ hasUnreadNotifChat }}</p>
                  </md-avatar>
                </md-button>

                <md-menu-content
                  :class="dashboardClass"
                  class="dashboard__message"
                >
                  <md-menu-item>
                    <span class="dashboard__message__title md-list-item-text">
                      Messages : {{ hasUnreadNotifChat }}
                    </span>
                    <!-- Vider la liste -->
                    <md-button
                      class="md-icon-button md-list-action"
                      @click.native="goToListChat"
                    >
                      <MeepIconTopBarBoxList class="app-icon toolbar-icon" />
                      <md-tooltip md-direction="top">
                        Vider la liste
                      </md-tooltip>
                    </md-button>

                    <md-button
                      class="md-icon-button md-list-action"
                      @click="clearNotificationsChat"
                    >
                      <MeepIconTopBarBoxDelete class="app-icon toolbar-icon" />
                      <md-tooltip md-direction="top">
                        Vider la liste
                      </md-tooltip>
                    </md-button>

                    <md-button
                      class="md-icon-button md-list-action"
                      @click.native="toggleNotificationsChat"
                    >
                      <MeepIconTopBarBoxClose
                        class="app-icon-stroke toolbar-icon"
                      />
                      <md-tooltip md-direction="top"> Fermer</md-tooltip>
                    </md-button>
                  </md-menu-item>

                  <md-menu-item
                    v-for="(notif, index) in notificationsChat"
                    :key="index"
                    class="dashboard__message__contents"
                  >
                    <div class="icon-circle">
                      <MeepIconHomepageChat
                        class="app-icon-inverted toolbar-icon"
                      />
                    </div>

                    <!-- -->
                    <div
                      class="md-list-item-text"
                      @click="removeNotif(notif.id)"
                    >
                      <router-link
                        v-if="notif.url"
                        :to="notif.url"
                        class="text-secondary"
                      >
                        {{ notif.text }}
                      </router-link>
                      <span v-else class="text-secondary">{{
                        notif.text
                      }}</span>
                    </div>

                    <!-- Bouton pour enlever l'élément de la liste -->
                    <md-button
                      class="md-icon-button md-list-action"
                      @click.native="removeNotif(notif.id)"
                    >
                      <MeepIconTopBarBoxDelete class="app-icon toolbar-icon" />

                      <md-tooltip md-direction="top"
                        >Retirer de la liste
                      </md-tooltip>
                    </md-button>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>

              <!-- Nom d'utilisateur -->
              <md-menu md-align-trigger md-direction="bottom-end" md-size="big">
                <md-button
                  class="button-menu dashboard-toolbar__button-menu"
                  md-menu-trigger
                >
                  <MeepIconPerson
                    class="
                      app-icon-inverted
                      dashboard__user-icon
                      dashboard-toolbar__icon
                      dashboard-icon
                    "
                  />

                  <span v-if="isSuperUser">{{ self.username }}</span>

                  <span v-else>
                    {{ self.firstname ? self.firstname[0].toUpperCase() : "" }}.
                    {{ self.lastname }}
                  </span>

                  <MeepIconArrowDropdown
                    class="dashboard__icon dashboard__dropdown-icon"
                  />
                </md-button>

                <md-menu-content
                  :class="dashboardClass"
                  class="dashboard__information"
                >
                  <md-menu-item
                    v-if="isSuperUser"
                    :to="'/admin/superusers/view/' + self.id"
                  >
                    <MeepIconPerson
                      class="dashboard__icon dashboard__icon--alt"
                    />

                    <span class="text-secondary">VOS INFORMATIONS</span>
                  </md-menu-item>

                  <md-menu-item
                    v-if="!isSuperUser && !isExternal"
                    to="/dashboard/information"
                  >
                    <MeepIconPerson class="dashboard-icon" />
                    <span class="text-secondary">{{
                      $t("dashboard.information.info")
                    }}</span>
                  </md-menu-item>

                  <md-menu-item to="/logout">
                    <MeepIconDisconnect class="dashboard-icon" />

                    <span class="text-secondary">{{
                      $t("dashboard.information.disconnect")
                    }}</span>
                  </md-menu-item>
                  <md-menu-item class="strikethrough" />
                  <md-menu-item to="/dashboard/terms-of-use">
                    <MeepIconLegal class="dashboard-icon" />

                    <span class="text-secondary">{{
                      $t("dashboard.information.legal")
                    }}</span>
                  </md-menu-item>

                  <md-menu-item to="/dashboard/cgv">
                    <MeepIconCgv class="dashboard-icon" />

                    <span class="text-secondary">{{
                      $t("dashboard.information.cgv")
                    }}</span>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </div>
          </md-app-toolbar>

          <!-- La side nav -->
          <md-app-drawer
            id="drawer"
            ref="drawer"
            :md-active.sync="isSideNavVisible"
            md-persistent="full"
          >
            <dash-menu />
          </md-app-drawer>

          <!-- La page active -->
          <md-app-content>
            <router-view id="app-content-view" />
          </md-app-content>
        </md-app>
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import { NOTIFICATIONS } from "@/constants";
import MeepIconArrowDropdown from "@/components/icons/MeepIconArrowDropdown";
import MeepIconTopBarBoxClose from "@/components/icons/MeepIconTopBarBoxClose";
import MeepIconTopBarBoxDelete from "@/components/icons/MeepIconTopBarBoxDelete";
import MeepIconTopBarBoxList from "@/components/icons/MeepIconTopBarBoxList";
import MeepIconCloud from "@/components/icons/MeepIconCloud";
import MeepIconNotifications from "@/components/icons/MeepIconNotifications";
import MeepIconPerson from "@/components/icons/MeepIconPerson";
import MeepIconHomepageChat from "@/components/icons/MeepIconHomepageChat";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies";
import MeepIconAssignment from "@/components/icons/MeepIconAssignment";
import MeepIconCgv from "@/components/icons/MeepIconCgv";
import MeepIconDisconnect from "@/components/icons/MeepIconDisconnect";
import MeepIconLegal from "@/components/icons/MeepIconLegal";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Dashboard",

  components: {
    MeepIconArrowDropdown,
    MeepIconTopBarBoxClose,
    MeepIconTopBarBoxDelete,
    MeepIconTopBarBoxList,
    MeepIconCloud,
    MeepIconNotifications,
    MeepIconPerson,
    MeepIconSettingCompanies,
    MeepIconAssignment,
    MeepIconHomepageChat,
    MeepIconCgv,
    MeepIconDisconnect,
    MeepIconLegal,
  },

  data() {
    return {
      isSideNavVisible: false,
      isMobile: false,
      isNotificationsVisible: false,
      isNotificationsChatVisible: false,
      NOTIFICATIONS: NOTIFICATIONS,
    };
  },

  computed: {
    ...mapGetters([
      "self",
      "isExternal",
      "isSuperUser",
      "isAdmin",
      "unreadNotifications",
      "unreadNotificationsChat",
      "notifications",
      "isJEP",
      "isCE",
      "isJEPA",
      "isMEEP",
      "isLCM",
      "isGC",
    ]),

    hasUnreadNotif() {
      return this.unreadNotifications.length;
    },

    hasUnreadNotifChat() {
      return this.unreadNotificationsChat.length;
    },

    notificationsChat() {
      return this.notifications.filter(
        notification => notification.notification_type === NOTIFICATIONS.CHAT
      );
    },

    notificationsNotChat() {
      return this.notifications.filter(
        notification => notification.notification_type !== NOTIFICATIONS.CHAT
      );
    },

    dashboardClass() {
      return {
        "dashboard-jep": this.isJEP,
        "dashboard-ce": this.isCE,
        "dashboard-jepa": this.isJEPA,
        "dashboard-meep": this.isMEEP,
        "dashboard-lcm": this.isLCM,
        "dashboard-gc": this.isGC,
      };
    },
  },

  watch: {
    $route() {
      document.querySelector(".md-app-scroller").scrollTop = 0;
      /* Faire remonter la page lors de la navigation */
      if (!this.isLargeScreen()) {
        this.hideSideNav();
      }

      this.hideNotifications();
    },
  },

  mounted() {
    if (this.isLargeScreen()) {
      this.showSideNav();
    }

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {
        passive: true,
      });
    }
  },

  methods: {
    ...mapMutations(["IS_MOBILE"]),

    ...mapActions([
      "REMOVE_NOTIFICATION",
      "REMOVE_ALL_NOTIFICATIONS",
      "READ_NOTIFICATION",
    ]),

    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },

    isLargeScreen() {
      return this.getWindowWidth() > 900;
    },

    toggleSideNav() {
      this.isSideNavVisible = !this.isSideNavVisible;
    },

    showSideNav() {
      this.isSideNavVisible = true;
    },

    hideSideNav() {
      this.isSideNavVisible = false;
    },

    toggleNotifications() {
      this.isNotificationsVisible = !this.isNotificationsVisible;
      this.isNotificationsChatVisible = false;
    },

    toggleNotificationsChat() {
      this.isNotificationsChatVisible = !this.isNotificationsChatVisible;
      this.isNotificationsVisible = false;
    },

    hideNotifications() {
      this.isNotificationsVisible = false;
    },

    removeNotif(notifId) {
      this.REMOVE_NOTIFICATION(notifId);
    },

    clearNotifications() {
      this.REMOVE_ALL_NOTIFICATIONS();
    },

    clearNotificationsChat() {
      this.REMOVE_ALL_NOTIFICATIONS(true);
    },

    readNotif(notifId) {
      this.READ_NOTIFICATION(notifId);
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.IS_MOBILE(this.isMobile);
    },

    goToListNotifications() {
      this.toggleNotifications();

      const path = "/dashboard/notifications";

      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    goToListChat() {
      this.toggleNotificationsChat();
      const path = "/dashboard/communicate/chat/";

      if (!this.$route.path.includes(path)) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable";

@media only screen and (max-width: 600px) {
  .md-title {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      display: inline;
      font-size: 18px;

      span {
        max-width: 150px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-top: 5px;
      }
    }

    .user-status {
      position: relative;
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 330px) {
  p {
    span {
      max-width: 120px !important;
    }
  }
}

.md-toolbar-section-end i.md-icon {
  font-size: 20px !important;
}

.md-menu-content {
  background-color: #fff;

  .md-menu-content-container {
    margin: 20px 18px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f5f7;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 11px;
      height: 63px;
      background-color: #b5b5b5;
    }
  }
}

.dashboard {
  &__notifications {
    width: 500px;
    max-width: 100%;

    &__title {
      font-size: 14px;
      font-family: var(--font-extrabold);
    }

    &__text {
      font-size: 14px;
      font-family: var(--font-x);
      justify-content: center;
      white-space: initial;
    }

    .md-list {
      &-item {
        margin-bottom: 10px;
      }

      &-item-content {
        justify-content: flex-start;
        gap: 15px;
        padding: 0;
        padding-right: 20px;

        .notification {
          &__icon.md-icon {
            margin-right: 0.5rem;
          }

          &__text {
            height: 2.4rem;

            .text-secondary {
              line-height: 1.2rem;
              height: 2.4rem;
              text-overflow: ellipsis;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              white-space: normal;
            }
          }
        }
      }
    }

    .md-icon-button {
      width: 27px;
      height: 27px;
      min-width: 27px;
      min-height: 27px;

      .md-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .md-button.md-list-action:last-of-type {
      margin-left: 0;
    }
  }

  &__message {
    width: 350px;
    max-width: 100%;

    &__title {
      font-size: 14px;
      font-family: var(--font-extrabold);
    }

    .md-list {
      &-item {
        margin-bottom: 10px;
      }

      &-item-content {
        justify-content: flex-start;
        gap: 15px;
        padding: 0;
        padding-right: 20px;
      }
    }

    &__contents {
      .md-list-item-content {
        justify-content: space-between;
        gap: 19px;
      }

      .text-secondary {
        white-space: initial;
      }

      .md-button {
        margin: 0;
      }
    }

    .md-icon-button {
      width: 27px;
      height: 27px;
      min-width: 27px;
      min-height: 27px;

      .md-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .md-button.md-list-action:last-of-type {
      margin-left: 0;
    }
  }

  &__information {
    width: 250px;
    max-height: 100%;

    .md-list-item-container {
      border-radius: 10px;
      overflow: hidden;
    }

    .md-list-item-content {
      justify-content: flex-start;
      gap: 10px;
      padding: 0;
      padding-left: 5px;
    }

    &.dashboard-info-jepa {
      .text-secondary {
        font-size: 13px;
      }
    }
  }
}

.dashboard.dashboard-jep,
.dashboard.dashboard-ce {
  .dashboard__toolbar.md-app-toolbar {
    .dashboard__toolbar-item {
      &--right {
        .md-menu {
          background-color: var(--bg-alternate);
          display: flex;
          align-items: center;

          .md-button {
            &.button-menu {
              background-color: var(--bg-alternate);
            }
          }
        }
      }
    }
  }
}

.dashboard.dashboard-jep,
.dashboard.dashboard-ce {
  .dashboard__toolbar.md-app-toolbar {
    .dashboard__toolbar-item {
      &--right {
        .dashboard-toolbar__button-menu {
          height: 100%;

          &:hover::before {
            opacity: 0;
          }
        }
      }
    }
  }
}

#app .dashboard {
  .dashboard__toolbar.md-app-toolbar {
    .dashboard__toolbar-item {
      &--right {
        .md-menu {
          background-color: var(--secondary);

          .md-avatar.md-avatar-icon {
            background-color: transparent;
            color: var(--text-primary);
            font-size: toRem(8);
            font-family: var(--font-x, var(--font));
            @include for-lg {
              font-size: toRem(14);
            }
          }
        }
      }
    }
  }

  &.dashboard-jepa {
    .dashboard__toolbar.md-app-toolbar {
      .dashboard__toolbar-item--right {
        .md-menu {
          background-color: #002f87;
        }
      }
    }
  }

  &.dashboard-gc {
    .dashboard__toolbar.md-app-toolbar {
      .dashboard__toolbar-item--right {
        .md-menu {
          background-color: var(--primary);
        }
      }
    }
  }
}

.dashboard-gc {
  .md-menu-content-container {
    .md-list {
      .notification:nth-child(6n + 2),
      .dashboard__message__contents:nth-child(6n + 2) {
        .icon-circle {
          background-color: var(--color-icon-1);
        }
      }

      .notification:nth-child(6n + 3),
      .dashboard__message__contents:nth-child(6n + 3) {
        .icon-circle {
          background-color: var(--color-icon-2);
        }
      }

      .notification:nth-child(6n + 4),
      .dashboard__message__contents:nth-child(6n + 4) {
        .icon-circle {
          background-color: var(--color-icon-3);
        }
      }

      .notification:nth-child(6n + 5),
      .dashboard__message__contents:nth-child(6n + 5) {
        .icon-circle {
          background-color: var(--color-icon-4);
        }
      }

      .notification:nth-child(6n + 6),
      .dashboard__message__contents:nth-child(6n + 6) {
        .icon-circle {
          background-color: var(--color-icon-5);
        }
      }

      .notification:nth-child(6n + 1),
      .dashboard__message__contents:nth-child(6n + 1) {
        .icon-circle {
          background-color: var(--color-icon-6);
        }
      }
    }
  }

  .dashboard__notifications__title {
    font-family: var(--font-bold);
  }
}

.dashboard__notifications--jep,
.dashboard__message--jep {
  .md-theme-default {
    .md-list-item-text {
      a {
        color: #000;
      }
    }
  }
}

.dashboard__notifications--jep,
.dashboard__message--jep {
  .md-theme-default {
    .md-list-item-text {
      a {
        color: #000;
      }
    }
  }
}

.dashboard {
  height: 100%;

  .md-app {
    height: 100%;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__user-icon {
    margin-right: 10px;
  }

  &__dropdown-icon {
    margin-left: 5px;
  }

  .dashboard__toolbar.md-app-toolbar {
    box-shadow: none;
    background: #f8f9fa;
    padding-left: 0px;
    padding-right: 0;
    height: 40px;
    min-height: unset;
    display: flex;
    align-items: stretch;
    @include for-lg {
      height: 60px;
    }

    .dashboard__toolbar-item {
      &--right {
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        .md-button {
          min-width: 50px;
        }

        .md-menu {
          background-color: var(--primary);
          display: flex;
          align-items: center;

          .md-button-content {
            font-size: toRem(9);
            @include for-lg {
              font-size: toRem(14);
            }
          }
        }
      }
    }

    .md-title {
      p {
        margin: 0;
        padding: 0;
      }

      font-weight: bold;
      font-size: 24px;

      .user-status {
        font-size: 18px;
      }
    }
  }

  .md-app-content {
    height: 100%;
    overflow: hidden;
    padding: 0;
    background-color: #eff3f7;
  }

  .md-app-drawer {
    width: 70px;
    max-width: calc(100vw - 125px);
    transition: all 0.5s ease-in-out;
    @include for-lg {
      width: 100px;
    }
  }
}

#container {
  min-height: 100%;
  position: relative;
}

#body {
  // padding-bottom: 10px; /* Height of the footer */
  height: 100%;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
}

.md-button .md-ripple {
  padding: 0;
  @include for-lg {
    padding: 0 toRem(16);
  }

  .md-button-content {
    .dashboard-toolbar {
      &__icon.md-icon {
        color: #ffffff;
      }
    }
  }
}

.dashboard-toolbar {
  &__button-menu {
    height: 100%;

    .md-button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      padding: 5px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin: 0;

    &.dashbord__noti-icon {
      margin-left: 20px;
    }

    &.dashboard__user-icon {
      margin-right: 10px;
    }
  }
}

.md-menu-content {
  .strikethrough {
    height: 3px;
    background: var(--primary);
    margin: 10px 0;
  }
}

#app-content-view {
  background-color: #eff3f7;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-jepa {
  .dashboard__notifications__text,
  .dashboard__message__text,
  &.dashboard__information {
    .text-secondary {
      font-size: 12px;
      color: #000;
      font-family: var(--font-x);
      text-decoration: none;
    }
  }
}

.dashboard-jep,
.dashboard-ce {
  .dashboard__notifications__text,
  .dashboard__message__contents,
  .dashboard__information {
    .text-secondary {
      font-size: 12px;
      color: #000;
      line-height: 17px;
      font-family: var(--font-x, var(--font));
      text-decoration: none;

      &:hover {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .dashboard-toolbar {
    &__button-menu {
      .md-button-content {
        span {
          font-family: var(--font-bold);
        }
      }
    }
  }
}

.dashboard-lcm {
  .dashboard__notifications__text,
  .dashboard__message__text,
  &.dashboard__information {
    .text-secondary {
      font-size: 12px;
      color: #000;
      font-family: var(--font-x);
      text-decoration: none;
    }

    .md-menu-content-container {
      padding: toRem(10) toRem(8);
    }
  }
}

.dashboard-ce {
  .dashboard__notifications__text,
  .dashboard__message__text,
  &.dashboard__information {
    .text-secondary {
      font-size: 12px;
      color: #000;
      font-family: var(--font);
      text-decoration: none;
    }

    .md-menu-content-container {
      padding: toRem(10) toRem(8);
    }
  }
}
</style>
