<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.747 26.747"><g fill="none" stroke="#000" stroke-width=".8" transform="rotate(45 6.687 16.144)"><circle cx="9.457" cy="9.457" r="9.457" stroke="none"/><circle cx="9.457" cy="9.457" r="9.057"/></g><path d="M8.928 10a.928.928 0 10.927.927.929.929 0 00-.927-.927zm0 1.184a.256.256 0 11.256-.256.257.257 0 01-.256.256zM18.009 10.591h-7.135a.336.336 0 100 .671h7.135a.336.336 0 100-.671zM8.928 12.542a.927.927 0 10.927.927.929.929 0 00-.927-.927zm0 1.184a.256.256 0 11.256-.256.257.257 0 01-.256.256zM18.009 13.133h-7.135a.336.336 0 100 .671h7.135a.336.336 0 100-.671zM8.928 15.079a.927.927 0 10.927.927.929.929 0 00-.927-.927zm0 1.184a.256.256 0 11.256-.256.256.256 0 01-.256.26zM10.538 16.011a.335.335 0 00.336.335h7.135a.336.336 0 100-.671h-7.135a.335.335 0 00-.336.336z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconTopBarBoxList'
  };
</script>
