<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fill-rule="nonzero" d="M7 10l5 5 5-5z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconArrowDropdown'
  };
</script>
