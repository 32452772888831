<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.915 22.915"><g transform="translate(-1679.1 -249.805)"><circle cx="11.057" cy="11.057" r="11.057" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".8" transform="translate(1679.5 250.205)"/><path d="M1691.575 267.386a6.12 6.12 0 01-3.542-1.106 5.908 5.908 0 01-2.13-2.744h-1.233a.718.718 0 01-.643-1.04l.382-.764a.714.714 0 01.643-.4h.369v-.147h-.753a.718.718 0 01-.643-1.039l.382-.765a.714.714 0 01.642-.4h.852a6.045 6.045 0 015.671-3.85 5.569 5.569 0 013.162.927.645.645 0 01.258.8l-.514 1.217a.646.646 0 01-.955.287 3.618 3.618 0 00-2.89-.462 3.288 3.288 0 00-1.713 1.079h4.094a.719.719 0 01.662 1l-.322.764a.716.716 0 01-.662.438h-4.646v.147h4.014a.718.718 0 01.662 1l-.322.764a.717.717 0 01-.662.439h-2.812a3.319 3.319 0 001.739 1.083 3.6 3.6 0 002.882-.48.646.646 0 01.936.235l.607 1.156a.645.645 0 01-.222.844 5.6 5.6 0 01-3.293 1.017zm-6.706-4.691h1.331a.421.421 0 01.4.29 5.134 5.134 0 004.975 3.56 4.7 4.7 0 002.688-.781l-.425-.81a4.4 4.4 0 01-3.382.481 4.109 4.109 0 01-2.682-2.128.421.421 0 01.375-.612h3.508l.218-.517h-4.22a.421.421 0 01-.418-.372 4.518 4.518 0 010-1.085.421.421 0 01.418-.373h4.956l.218-.517h-4.683a.421.421 0 01-.375-.611 4.086 4.086 0 012.649-2.124 4.493 4.493 0 013.372.451l.359-.851a4.675 4.675 0 00-2.578-.716 5.2 5.2 0 00-4.975 3.56.421.421 0 01-.4.29h-1.072l-.259.517h.994a.421.421 0 01.419.454 5.726 5.726 0 000 .921.42.42 0 01-.419.454h-.729zm9.477 3.228z"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCgv'
  };
</script>
